<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ item.id ? 'Edit' : 'New' }} residency documentation<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'researcher.administration.residency-documentation.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="loggedUser.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'ResidencyDocumentation')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="item" />

            <div class="card">
              <div
                v-b-toggle="`collapseContent`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseContent`"
                visible
              >
                <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.type'] }}</label>
                          <v-select
                            v-model="item.authorization"
                            label="name"
                            :options="authorizations"
                            :get-option-key="option => option.id"
                            :disabled="!loggedUser.roles.includes('super-admin')"
                            @search="onSearch({ name: $event }, 'residencyDocumentationAuth/filter')"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.stage'] }}</label>
                          <v-select
                            v-model="item.stage"
                            label="name"
                            :options="stages"
                            :get-option-key="option => option.id"
                            :disabled="!loggedUser.roles.includes('super-admin')"
                            @search="onSearch({ name: $event }, 'residencyDocumentationStag/filter')"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.status'] }}</label>
                          <v-select
                            v-model="item.status"
                            label="status"
                            :options="statuses"
                            :get-option-key="option => option.id"
                            :disabled="!loggedUser.roles.includes('super-admin')"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <hr>
                      </div>
                    </div>
                    <h3 class="cart-title">
                      <a data-action="collapse">Dates</a>
                    </h3>
                    <div class="row">
                      <div class="col-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.issued_on_since'] }}</label>
                          <date-picker
                            v-model="item.begin_date"
                            format="D MMM Y"
                            value-type="format"
                            class="w-100"
                            :disabled="!loggedUser.roles.includes('super-admin')"
                            @change="calculateLimit"
                          />
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labels['content.expire_date'] }}</label>
                          <date-picker
                            v-model="item.end_date"
                            format="D MMM Y"
                            class="w-100"
                            value-type="format"
                            :disabled="!loggedUser.roles.includes('super-admin')"
                            @change="calculateLimit"
                          />
                        </div>
                        <div
                          v-if="!item.end_date"
                          class="form-check"
                        > <!-- si se marca el campo fecha de expire date que se ponga disabled -->
                          <input
                            id="tableColumn6"
                            type="checkbox"
                            class="form-check-input"
                            :checked="!item.end_date"
                            :disabled="!item.end_date"
                          >
                          <label
                            class="form-check-label"
                            for="tableColumn6"
                          >{{ labels['content.expire_certificate'] }}</label>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="dateAlert <= 60 && dateAlert > 0"
                      id="alert-expire"
                      class="row mt-2"
                    > <!-- cuando falten 60 días se genera la alerta y se envían notificaciones -->
                      <div class="col">
                        <div class="alert alert-warning">
                          <div class="alert-body">
                            <i data-feather="alert-triangle" /> There are <strong>{{ dateAlert }} days left</strong> for the expiration of the residence permit
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <div class="card">
              <div
                v-b-toggle="`collapseIcreaRemark`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">ICREA's remarks</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseIcreaRemark`"
              >
                <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >{{ labelsIcreaRemarks['content'] }}</label>
                          <quill-editor
                            v-if="loggedUser.roles.includes('super-admin')"
                            v-model="item.icrea_remarks"
                          />
                          <div
                            v-else
                            class="form-control"
                            disabled
                            v-html="item.icrea_remarks"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <AddFile
              :id="6"
              :title="'Support documentation'"
              :type="4"
              :files="item.files_"
              :can-edit="canEdit"
            />

            <InternalNotes
              :notes="item.notes"
              :can-edit="canEdit"
              @saveNewNote="saveNewNote"
            />

            <AttachedForm
              :type="'ResidencyDocumentation'"
              :model-id="residencyDocumentationId"
            />
          </div>
        </div>

        <div
          v-if="loggedUser.roles.includes('super-admin')"
          class="page-actions"
        >
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import AddFile from '../../../admin/senior-call/components/AddFile.vue'
import SearchResearcher from '../../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import AttachedForm from '../../../forms/partials/AttachedForm.vue'

export default {
  components: {
    AddFile,
    SearchResearcher,
    InternalNotes,
    vSelect,
    DatePicker,
    BCollapse,
    AttachedForm,
  },
  data() {
    return {
      residencyDocumentationId: this.$route.params.id,
      dateAlert: 0,
      sending: false,
      errors: '',
      canEdit: true,
      labelsIcreaRemarks: [],
    }
  },
  computed: {
    ...mapGetters({
      item: 'residencyDocumentation/item',
      statuses: 'status/statuses',
      authorizations: 'residencyDocumentationAuth/items',
      stages: 'residencyDocumentationStag/items',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
      user: 'users/user',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'administration.residency_documentation')
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.icrea_remarks`)
      this.labelsIcreaRemarks = data.data
    } catch (e) {
      console.error(e)
    }

    if (this.residencyDocumentationId) {
      await this.$store.dispatch('residencyDocumentation/fetchId', this.residencyDocumentationId)
      this.calculateLimit()
    } else {
      await this.$store.dispatch('residencyDocumentation/cleanType')
      Vue.set(this.item, 'user', this.user)
    }

    this.canEdit = !!this.loggedUser.roles.includes('super-admin')

    this.$store.dispatch('status/filterStatus', 'Residency Documentation')
  },
  methods: {
    calculateLimit() {
      if (this.item.end_date) {
        const date1 = new Date()
        const date2 = new Date(this.item.end_date)
        const diffTime = Math.abs(date2 - date1)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 0) {
          this.dateAlert = diffDays
          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 200)
        }
      }
    },
    saveFiles(files) {
      this.item.files_new = files.data
    },
    async save() {
      this.sending = true
      this.item.attached_form = this.attached

      try {
        if (this.residencyDocumentationId) {
          await this.$store.dispatch('residencyDocumentation/update', { id: this.residencyDocumentationId, data: this.item })
        } else {
          await this.$store.dispatch('residencyDocumentation/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The documentation has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'researcher.administration.residency-documentation.index' })
        })
      } else {
        Vue.swal('Error on saving the documentation.', 'Please, fill up all the required fields', 'error')
      }

      this.sending = false
    },
    saveNewNote(note) {
      this.item.notes.push(note)
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
